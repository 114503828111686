$motif-font-path: '~@ey-xd/motif-react/assets/fonts';
@import '~@ey-xd/motif-react/styles.scss';
@import './variables.module.scss';
@import 'spacing-helpers.scss';

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

*:focus:not(.focus-visible) {
    outline: none;
}

*:focus {
    outline: none !important;
}

a {
    border: 0 !important;
    box-shadow: none !important;
}

.focus-visible {
    outline: lightgreen solid 2px !important;
}

.tab-div {
    height: 60px;
    background-color: #2e2e38;
    color: #fff;
}

.motif-label {
    color: #fff;
    width: 150px;
    padding-left: 10px;
}

// .emission-container {
//   margin-top: -40px;
// }

.three-layout {
    gap: 15px;
}

.flex-container {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    // justify-content: space-between;

    // margin: -40px 0 0 -10px;
    > .no-data {
        display: none;

        &:only-of-type {
            display: block;
            flex: 1;
        }
    }
}

.flex-item {
    width: calc(33.1% - 5px);
}

.flex-item:empty {
    display: none;
}

.flex-1 {
    margin-left: 0;
}

.flex-2 {
    width: calc(67% - 10px);
}

.flex-3 {
    width: 100%;
    margin-left: 0;
}

.flex-4 {
    width: calc(50% - 5px);
}

.show-widget {
    display: block !important;

    .flex-container {
        margin: 0;
    }
}

.hide {
    .flex-2 {
        display: none;
    }

    .flex-1 {
        display: none;
    }

    .flex-3 {
        display: none;
    }

    .flex-4 {
        display: none;
    }
}

.highcharts-tooltip {
    span {
        background-color: $header-gray !important;
        color: #fff !important;
    }
}

.table-header-row {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
}

.tablecell-width {
    width: 10% !important;

    .motif-label {
        color: #fff;
        width: 10%;
        padding-left: 0px !important;
    }
}

.first-column {
    width: 28% !important;
}

.emission-tablerow-width {
    width: 100% !important;
}

/* NavBar Styling */
.nav-bar-wrapper {
    width: 100%;

    .motif-tab-navigation {
        .motif-tab-button {
            margin: 0px 6px;
            padding: 7px 0px;
            font-size: $font-size-16;
            line-height: 22px;
            display: flex;
            align-items: baseline;
            border-bottom: 4px solid transparent;
            transition: none;

            &:first-child {
                margin-left: 0rem;
            }

            .motif-icon {
                margin-right: 0px;

                svg {
                    height: 9px;
                    width: 8px;
                }
            }

            .main-nav-item {
                padding: 0px 14px 0px 7px;

                &:first-child {
                    padding-left: 0px;
                }
            }

            &.motif-active {
                border-bottom-color: var(--tab-navigation-hover-font-color);
                text-shadow: 1px 0 0 var(--tab-navigation-font-color);
            }
        }
    }
}

/* This is for Modelling Input screen */
.page-header {
    display: flex;
    align-items: center;

    .page-description {
        width: 97%;
        margin-right: 20px;

        // p {
        //   color: $white;
        //   font-size: $font-size-small;
        //   line-height: 24px;
        //   font-weight: 500;
        // }
    }

    .edit-icon {
        width: 3%;
    }
}

.modelling-filter-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    border: 1px solid $gray;
    background-color: $alternateblack;

    // & > div {
    //   width: 15%;
    // }
}

.legend-label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.07px;
}

.section-list {
    display: flex;
    justify-content: end;
}

.flexbox-column {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 5px;

    .column-item-2 {
        width: calc(50% - 8px);
    }

    .column-item-3 {
        width: calc(33% - 8px);
    }

    .column-item-4 {
        width: calc(67% - 8px);
    }
}

.em-sector-temp-fixed {
    &.hide {
        margin-top: 0px;
    }
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 4px;
    background-color: $alternateblack;
}

::-webkit-scrollbar-track {
    background: transparent !important;
}

::-webkit-scrollbar-thumb {
    background: rgba(145, 158, 171, 0.6) !important;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    background-color: $alternateblack;
}

.the-world-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid $gray;
    background-color: $alternateblack;
    width: 130px;
    font-size: 13px;
}

.the-world-section-year {
    padding-left: 5px;
    color: yellow;
    font-size: 13px;
}

.rc-slider-mark-text {
    color: #ffffff !important;
}

.combined-chart-options {
    width: 100%;
    margin-top: 13px;
    .combine-filter-option {
        width: 100%;
        display: flex;
    }
    .index-of-input-chip {
        margin-left: 2rem;
        .motif-chip-success {
            border-color: $yellow;
            background-color: $yellow;
            font-weight: 500;
            b {
                margin-right: 5px;
            }
        }
    }
    .combined-charts-icon {
        display: flex;
        position: relative;
        .sub-combined-charts-icon {
            display: flex;
            flex: 1;
            position: absolute;
            right: 0;
        }
        .sub-icon-1 {
            right: 128px;
        }
        .motif-icon {
            height: 14px;
            width: 14px;
        }
        .motif-label {
            flex: 1;
            font-size: 13px;
            font-weight: 400;
            min-width: 100px;
            padding-right: 5px;
        }
    }
}

.loadIndicator {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.motif-avatar-status-icon-none {
    display: none;
}
