@import 'Colors.scss';

.cim-footer {
    text-align: right;
    z-index: 10000000;
    position: relative;
    border: 0;
    padding: 1rem !important;
    height: auto !important;
    .cim-footer-engagement,
    .cim-footer-terms {
        margin-right: 25px;
    }
    .cim-footer-links {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $grey-300;
    }
    .flex-item {
        flex: 1;
    }
    .legal-statement {
        text-align: left;
        flex: 2;
    }
    .cim-footer-disclaimer {
        margin-left: 25px;
    }
}
