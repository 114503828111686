.hide-for-accessibility {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.motif-header {
  z-index: 9999;
}
.motif-header-logo {
  .motif-icon {
    position: relative;
    bottom: -1px;
    width: 32px;
    height: 32px;
  }
}
.motif-header-app-name {
  position: relative;
  bottom: 4px;
  margin-left: 11px;
  font-weight: 400 !important;
  font-size: 18px !important;
}
.motif-header {
  .motif-header-vertical-navigation-open-button {
    height: 2rem !important;
    .motif-icon-button {
      height: 2.6rem !important;
    }
  }
  .motif-header-seprator {
    float: left;
    width: 2px;
    position: absolute;
    margin-left: 9px;
  }
  .motif-dropdown-item {
    .motif-avatar {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    img {
      margin-right: 10px;
    }
  }
}
.motif-header-profile-card {
  position: relative;
  top: 14px;
  right: 19px;
  .motif-avatar {
    cursor: pointer;
  }
}
